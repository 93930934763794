import { Link } from "react-router-dom";
import "./Description.css";

const Description = () => {
  return (
    <div className="h-desc pad-1">
      <p className="tagline">
        <span>&mdash;&mdash; </span>ABOUT US
      </p>
      <h1 className="head-1">Graha Prabala Sejahtera - Tiga Dara Property</h1>
      <aside className="p-content">
        <p>
          Graha Prabala Sejahtera - Tiga Dara Property terinspirasi dari
          kebutuhan hunian kekinian dengan konsep <b>green</b>. Graha Prabala
          Sejahtera - Tiga Dara Property memiliki beberapa faktor poin, yaitu{" "}
          <b>lokasi yang strategis</b>, konsep dengan <b>ruangan yang lega</b>,{" "}
          <b>kawasan hijau</b> dengan pohon rindang, <b></b> lingkungan, model
          bangunan yang mudah dikembangkan, rindang, <b>keamanan</b> lingkungan,
          model bangunan yang mudah dikembangkan, dan fasum yang luas,{" "}
          <b>playground</b>, serta prasarana jalan yang <b>lebar</b>. Faktor
          poin tersebut menjadi acuan kami untuk awal merencanakan sebuah
          hunian.
        </p>
        <p>
          Terinspirasi dari kebutuhan hunian kekinian itulah Graha Prabala
          Sejahtera - Tiga Dara Property berasal, membuat kawasan baru yang
          berlokasi di Jalan Gito-Gati. Kawasan baru kami memiliki total luas 2
          Ha, yang berarti juga memilki potensi baru untuk perkembangan yang
          luar biasa.
        </p>
        <p>
          Graha Prabala Sejahtera - Tiga Dara Property sendiri adalah sebuah
          kawasan hunian rumah yang memiliki konsep <b>green</b> yang akan memberikan
          pengalaman tinggal serasa di pegunungan. Tinggal di tempat strategis
          serasa tinggal di daerah yang sejuk.
        </p>
        <p>
          <b>Griya Alta Pesona</b> adalah cluster pertama yang akan di tawarkan
          di Graha Prabala Sejahtera - Tiga Dara Property dengan Promo Special untuk kamu.{" "}
          <b>Perumahan Griya Alta Pesona Tiga Dara Properti</b> memiliki tipe 100
          yang mana cocok untuk dikembangkan lagi.
        </p>

        <div style={{ paddingTop: 25 }}>
          <Link to="/produk">
            <button className="button-1">Produk</button>
          </Link>
        </div>
      </aside>
    </div>
  );
};

export default Description;
