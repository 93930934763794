import "./Detail.css";
import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";

const Detail = (props) => {
    const { ref, inView } = useInView({
        threshold: 0.2
    });
    const animationLeft = useAnimation();
    const animationRight = useAnimation();

    const [img, setImg] = useState(props?.data[1]);

    useEffect(() => {
        if (inView) {
            animationLeft.start({
                x: 0,
                transition: {
                    type: 'spring', duration: 2, bounce: 0
                },
            });

            animationRight.start({
                opacity: 1,
                scale: 1,
                transition: {
                    type: 'spring', duration: 2, bounce: 0.5
                },
            });
        }
    }, [inView, animationLeft, animationRight]);

    return (
        
        <div className="h-detail">
            <p><span>&mdash;&mdash; </span>KAWASAN</p>
            <h1>Griya Alta Pesona</h1>
            <aside ref={ref}>
                <motion.img
                    initial={{ x: '-100vh' }}
                    animate={animationLeft}
                    src={props?.data[0]} alt="entrance50" className="l-img" />
                <motion.div
                    initial={{ opacity: 0, scale: 0.5 }}
                    animate={animationRight}
                >
                    <div className="r-content">
                        <img src={img} alt="detail50" className="r-img" />
                        <ul className="control">
                            <li onClick={() => setImg(props?.data[1])} className={img === props?.data[1] ? "active" : ""}></li>
                            <li onClick={() => setImg(props?.data[2])} className={img === props?.data[2] ? "active" : ""}></li>
                        </ul>
                    </div>
                    <p>Mempersembahkan hunian berkonsep green dengan berbagai macam aspek kenyamanan.</p>
                    <h2>TIPE 100</h2>
                    <div className="r-detail">
                        <p className="box"><b>{img === props?.data[1] ? "01" : "02"}</b> <span style={{ color: "#D9D9D9" }}>/ 02</span></p>
                        <div className="button-group">
                            <Link to="/produk?kawasan=griyaaltapesona"><button className="box">Produk</button></Link>
                            <Link to="/griyaaltapesona/detail"><button className="box">Detail</button></Link>
                        </div>
                    </div>
                </motion.div>
            </aside>
        </div>
    );
};

export default Detail;