import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import LCard from "../../../../components/LCard/LCard";
import "./List.css";

const List = ({ produk }) => {
    const { ref, inView } = useInView({
        threshold: 0.5
    });
    const animation = useAnimation();

    useEffect(() => {
        if (inView) {
            animation.start({
                opacity: 1,
                scale: 1,
                transition: {
                    duration: 2,
                    type: 'spring',
                    bounce: 0.3
                }
            })
        }
    }, [inView, animation]);
    return (
        <div className="h-list" ref={ref}>
            <div className="pad-2">
                <div className="list-h">
                    <div>
                        <p className="tagline"><span>&mdash;&mdash; </span>KAWASAN</p>
                        <section>
                            <h2 className="head-1">Produk Griya Alta Pesona</h2>
                            <Link className="link mobile-1" to="/produk?kawasan=griyaaltapesona">Lihat selengkapnya <span>&#10230;</span></Link>
                        </section>
                    </div>
                </div>
            </div>
            <div className="list-card pad-1">
                {produk?.map((item, index) =>
                (<motion.div
                    initial={{
                        opacity: 1,
                        scale: 0
                    }}
                    animate={animation}
                    className="content" key={index}>
                    <LCard item={item} />
                </motion.div>)
                )
                }
            </div>
            <div className="pad-2">
                <Link className="link mobile-2" to="/produk?kawasan=griyaaltapesona">Lihat selengkapnya <span>&#10230;</span></Link>
            </div>
        </div>

    );
};

export default List;