import { Homepage } from "../../../assets";

const Header = () =>{
    return(
        <div>
            <img src={Homepage} alt="homepage" style={{width: "100%"}}/>
        </div>
    );
};

export default Header;