import TClient from "./TClient/TClient";
import TForm from "./TForm/TForm";
import { Helmet } from "react-helmet-async";
import { useEffect, useState } from "react";
import Api from "../../controller/testimoni";
import "./index.css";
import Loading from "../../components/Loading/Loading";
import Maintenance from "../Maintenance/Maintenance";




const Testimoni = () => {
    const [testimoni, setTestimoni] = useState(null);
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        Api.get()
            .then((res) => {
                setTestimoni(res?.data);
            })
            .catch((error) => {
                console.log(error);
                setError(error.message);
            })
            .finally(() => {
                setLoading(false);
            })
            // eslint-disable-next-line
    }, [testimoni?.status]);
    return (
        <>
            <Helmet>
                <title>Testimoni Tiga Dara Properti</title>
                <meta
                    name="description"
                    content="Testimoni perumahan Griya Alta Pesona jogja, penasaran dengan produk rumah kami? bisa cek testimoninya!" />
                <link rel="canonical" href="/produk" />
            </Helmet>

            {loading && <Loading />}
            {error && <Maintenance error={error} />}
            {
                testimoni && <div className="testi">
                    <div className="left">
                        <TForm />
                    </div>
                    <div className="right">
                        <TClient testimoni={testimoni?.data} />
                    </div>
                </div>
            }
        </>

    )
}

export default Testimoni;