import { TCard } from "../../../components";
import "./TClient.css";

const TClient = ({ testimoni }) => {
    return (
        <>
            <h1>Testimoni dari client</h1>
            {testimoni?.length > 0 ?
                <div className="t-box">
                    {testimoni.map((item, index) => (
                        <div style={{ marginBottom: 20 }} key={index}>
                            <TCard testimoni={item} />
                        </div>
                    ))}
                </div> :
                <h2>Belum ada testimoni</h2>}
        </>
    )
}

export default TClient;