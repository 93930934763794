import "./Brosur.css";
import React, { useEffect } from "react";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";

const Brosur = (props) => {
    const { ref, inView } = useInView({
        threshold: 0.1
    })
    const animation = useAnimation();

    useEffect(() => {
        if (inView) {
            animation.start({
                y: 0,
                opacity: 1,
                transition: {
                    duration: 1,
                    type: 'spring',
                    bounce: 0
                },
            })
        }
    }, [inView, animation]);
    return (
        <motion.div
            initial={{
                y: '50vh',
                opacity: 0
            }}
            animate={animation}
            className="brosur" ref={ref}>
            <h2 className="head-1">Brosur & Pricelist Griya Alta Pesona</h2>
            <h3 className="para-1">Download brosur & pricelist Perumahan Griya Alta Pesona dan dapatkan rincian harganya.</h3>
            <a href={props.link} target="blank"><button className="button-1">Download</button></a>
        </motion.div>
    );
};

export default Brosur;