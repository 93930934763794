import React, { useEffect } from "react";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import "./Area.css";

const Area = ({ image, description }) => {
  const { ref, inView } = useInView({
    threshold: 0.3,
  });

  const animation = useAnimation();
  const fade = useAnimation();

  useEffect(() => {
    if (inView) {
      animation.start({
        y: 0,
        opacity: 1,
        transition: {
          duration: 1,
          type: "spring",
          bounce: 0,
          delay: 1,
        },
      });

      fade.start({
        opacity: 1,
        transition: {
          duration: 1,
        },
      });
    }
  }, [inView, animation, fade]);

  return (
    <div ref={ref} className="d-area bg-base">
      <h2>Kawasan</h2>
      <motion.div
        initial={{ opacity: 0 }}
        animate={fade}
        className="d-bg"
        style={{ backgroundImage: `url(${image})` }}
      ></motion.div>
      <motion.div
        initial={{
          y: "200px",
          opacity: 0,
        }}
        animate={animation}
        className="description bg-white"
      >
        <h3>Deskripsi Kawasan</h3>
        <p>{description}</p>
      </motion.div>
    </div>
  );
};

export default Area;
