import { Template } from "../../../assets";
import {IoCheckbox} from "react-icons/io5";
import "./Reason.css";

const Reason = () =>{
    const data = [
        "Lokasi yang sangat strategis",
        "Konsep desain ruangan yang lega",
        "Kawasan hijau dengan pohon rindang sehingga udara semakin sejuk",
        "Keamanan lingkungan yang terjaga",
        "Model bangunan yang mudah dikembangkan",
        "Fasum yang luas, playground, dan prasarana jalan yang lebar"
    ];
    
    return(
        <div className="h-reason pad-1">
            <img src={Template} alt="template" className="image"/>
            <div>
            <p className="tagline"><span>&mdash;&mdash; </span>ABOUT US</p>
            <h2 className="head-1">Mengapa memilih Griya Alta Pesona?</h2>
            <aside className="p-content">
                <p>Griya Alta Pesona mempersembahkan hunian berkonsep <b>green</b> dengan aspek kenyamanan sebagai berikut :</p>
                <div className="h-list">
                    {data.map((item, index)=>(
                        <div key={index} className="h-item">
                            <IoCheckbox size={25} className="icon"/>
                            <p>{item}</p>
                        </div>
                    ))}
                </div>
            </aside>
            </div>
        </div>
    );
};

export default Reason;