import Brosur from "./Brosur/Brosur";
import Testimoni from "./Testimoni/Testimoni";
import Head from "./Head/Head";
import Detail from "./Detail/Detail";
import Denah from "./Denah/Denah";
import { useEffect, useRef, useState } from "react";
import Prasarana from "./Prasarana/Prasarana";
import Spesifikasi from "./Spesifikasi/Spesifikasi";
import TApi from "../../../controller/testimoni";
import PApi from "../../../controller/produk";
import { Helmet } from "react-helmet-async";
import Lokasi from "./Lokasi/Lokasi";
import Maintenance from "../../Maintenance/Maintenance";
import List from "./List/List";
import Visual from "./Visual/Visual";
import homepageJson from "../../../constant/homepage.json";

const Homepage = () => {
  const [product, setProduct] = useState(null);
  const data = homepageJson.data;
  const [error, setError] = useState(null);
  const [terror, setTerror] = useState(null);
  const [testimoni, setTestimoni] = useState(null);
  const spek = useRef(null);
  const executeScroll = () =>
    spek.current.scrollIntoView({ behavior: "smooth", block: "start" });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    PApi.paresia()
      .then((res) => {
        setProduct(res?.data);
      })
      .catch((error) => {
        setError(error.message);
        console.log(error);
      });
    // eslint-disable-next-line
  }, [product?.status]);

  useEffect(() => {
    TApi.get()
      .then((res) => {
        setTestimoni(res?.data);
      })
      .catch((error) => {
        setTerror(error.message);
      });
    // eslint-disable-next-line
  }, [testimoni?.code]);

  return (
    <>
      <Helmet>
        <title>Produk - Perumahan Griya Alta Pesona</title>
        <meta
          name="description"
          content="Perumahan Griya Alta Pesona Jogja hunian berkonsep hijau dan sejuk, lokasi strategis, konsep desain yang lega, keamanan terjaga, jalan lebar, dan fasilitas lengkap."
        />
        <link rel="canonical" href="/" />
      </Helmet>
      {error && <Maintenance error={error} />}
      {data && (
        <div>
          <Head executeScroll={executeScroll} img={data.jumbotronAbove} />
          <Detail data={data?.type} />
          <Visual />
          <Lokasi />
          <List produk={product?.data?.slice(0, 5)} />
          <Denah img={data?.plan} />
          {/* <Prasarana img={data?.security} /> */}
          <Prasarana img={data.security} />
          <Spesifikasi data={spek} img={data.jumbotronBelow} />
          <Brosur link={data?.brochure} />
          <Testimoni testimoni={testimoni?.data} error={terror} />
        </div>
      )}
    </>
  );
};

export default Homepage;
