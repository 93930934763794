import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import "./Visual.css";
import { useEffect } from "react";
import { Visual1, Visual2 } from "../../../../assets";

const Visual = () => {
  const { ref, inView } = useInView({
    threshold: 0.5,
  });
  const animation = useAnimation();

  useEffect(() => {
    if (inView) {
      animation.start({
        opacity: 1,
        scale: 1,
        transition: {
          duration: 2,
          type: "spring",
          bounce: 0.3,
        },
      });
    }
  }, [inView, animation]);

  return (
    <div className="h-visual" ref={ref}>
      <div className="pad-2">
        <div className="list-h">
          <div>
            <p className="tagline">
              <span>&mdash;&mdash; </span>KAWASAN
            </p>
            <section>
              <h2 className="head-1">
                Visual Tampak{" "}
                <span
                  style={{
                    fontSize: 0,
                    opacity: 0,
                  }}
                >
                  Griya Alta Pesona
                </span>
              </h2>
            </section>
          </div>
        </div>
      </div>
      <motion.div
        className="pad-2"
        initial={{
          opacity: 1,
          scale: 0,
        }}
        animate={animation}
      >
        <div className="visual-container">
          <div className="visual-content">
            <h3>Type 1</h3>
            <img src={Visual1} alt="visual 1" />
          </div>
          <div className="visual-content">
            <h3>Type 2</h3>
            <img src={Visual2} alt="visual 1" />
          </div>
        </div>
      </motion.div>
    </div>
  );
};

export default Visual;
