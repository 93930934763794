import "./TForm.css";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { phoneRegExp } from "../../../constant/config";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Modal } from "../../../components";
import Api from "../../../controller/testimoni";

const TForm = () => {
    const history = useNavigate();
    const [openModal, setOpenModal] = useState(false);
    const [failed, setFailed] = useState(null);

    const handleModal = () => {
        setOpenModal(false);
        history("/");
    }

    const doTestimoni= (values) => {
        Api.post(values)
            .then(() => {
                formik.setSubmitting(false);
                formik.resetForm();
            })
            .catch((error) => {
                setFailed(error.message);
            })
            .finally(() => {
                setOpenModal(true);
            });
    }

    const formik = useFormik({
        initialValues: {
            name: '',
            phoneNumber: '',
            email: '',
            words: '',
            anonim: false,
        },
        validationSchema: Yup.object({
            name: Yup.string(),
            phoneNumber: Yup.string()
                .required('Tidak boleh kosong')
                .matches(phoneRegExp, 'Nomer HP tidak valid'),
            email: Yup.string()
                .required('Tidak boleh kosong')
                .email('Format email tidak valid'),
            words: Yup.string()
                .required('Tidak boleh kosong'),
            anonim: Yup.bool()
        }),
        onSubmit: doTestimoni
    })

    return (
        <>
            <Modal 
            open={openModal} 
            onClose={handleModal} 
            message="Terima kasih, words dari anda sangat bermanfaat untuk kami." 
            error={failed}/>
            <h2>Form Testimoni</h2>
            <form onSubmit={formik.handleSubmit}>
                <div className="t-text">
                    <div className="t-group">
                        <label>Nama</label>
                        <input
                            type="text"
                            name="name"
                            placeholder="silahkan isi nama anda"
                            {...formik.getFieldProps('name')}
                        />
                    </div>
                    <div className="t-group">
                        <label>No HP</label>
                        <input
                            type="text"
                            name="phoneNumber"
                            placeholder="silahkan isi no hp anda"
                            {...formik.getFieldProps('phoneNumber')}
                        />
                        {formik.touched.phoneNumber && formik.errors.phoneNumber && <div className="error">{formik.errors.phoneNumber}</div>}
                    </div>
                    <div className="t-group">
                        <label>Email</label>
                        <input
                            type="text"
                            name="email"
                            placeholder="silahkan isi email anda"
                            {...formik.getFieldProps('email')}
                        />
                        {formik.touched.email && formik.errors.email && <div className="error">{formik.errors.email}</div>}
                    </div>
                    <div className="t-group">
                        <label>Testimoni</label>
                        <textarea
                            type="text"
                            name="words"
                            placeholder="silahkan isi words anda"
                            {...formik.getFieldProps('words')}
                        />
                        {formik.touched.words && formik.errors.words && <div className="error">{formik.errors.words}</div>}
                    </div>
                    <div className="t-checkbox">
                        <input
                            type="checkbox"
                            name="anonim"
                            id="anonim"
                            checked={formik.values.anonim}
                            {...formik.getFieldProps('anonim')}
                        />
                        <label htmlFor="anonim">Sembunyikan nama saya</label>
                    </div>
                    <button type="submit" disabled={formik.isSubmitting}>KIRIM TESTIMONI</button>
                </div>
            </form>

        </>
    )
}

export default TForm;