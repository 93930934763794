import "./Promo.css";
import { Link } from "react-router-dom";
import React, { useEffect } from "react";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { WA } from "../../../../constant/config";

const Promo = ({id}) => {
    const { ref, inView } = useInView({
        threshold: 0.3
    });

    const animation = useAnimation();
    const fade = useAnimation();

    useEffect(() => {
        if (inView) {
            animation.start({
                y: 0,
                opacity: 1,
                transition: {
                    duration: 0.5,
                    type: '',
                    bounce: 0,
                },
            });

            fade.start({
                opacity: 1,
                transition: {
                    duration: 1,
                }
            })
        }

    }, [inView, animation, fade]);
    return (
        <div className="d-promo" ref={ref}>
            <motion.div
                initial={{
                    opacity: 0
                }}
                animate={fade}
                className="p-left box">
                <p>Tertarik dengan Perumahan Griya Alta Pesona?</p>
                <p>Booking sekarang atau chat dengan tim marketing kami.</p>
            </motion.div>
            <div className="p-right box">
                <Link to={id ? "/griyaaltapesona/booking/" + id : "/griyaaltapesona/booking"}>
                    <motion.button
                        initial={{
                            y: '-100px',
                            opacity: 0
                        }}
                        animate={animation}
                        className="p-book">Book Now</motion.button>
                </Link>
                <a href={"https://wa.me/" + WA} target="blank">
                    <motion.button
                        initial={{
                            y: '100px',
                            opacity: 0
                        }}
                        animate={animation}
                        className="p-wa">Whatsapp</motion.button>
                </a>
            </div>
        </div>
    );
};

export default Promo;