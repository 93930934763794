import { IoLocationSharp, IoBedOutline } from "react-icons/io5";
import { BsHouseDoor } from "react-icons/bs";
import { BiBath, BiArea } from "react-icons/bi";
import { TbParking } from "react-icons/tb";
import { Link } from "react-router-dom";
import React, { useEffect } from "react";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import "./General.css";

const General = ({
  tipe,
  name,
  id,
  description,
  size,
  bedroom,
  bathroom,
  field,
  image,
}) => {
  const { ref, inView } = useInView({
    threshold: 0.2,
  });

  const animation = useAnimation();
  const fade = useAnimation();

  useEffect(() => {
    if (inView) {
      animation.start({
        y: 0,
        opacity: 1,
        transition: {
          duration: 1,
          type: "spring",
          bounce: 0,
        },
      });

      fade.start({
        opacity: 1,
        transition: {
          duration: 1,
        },
      });
    }
  }, [inView, animation, fade]);

  return (
    <div className="d-general" ref={ref}>
      <motion.img
        initial={{
          y: "100px",
          opacity: 0,
        }}
        animate={animation}
        src={image}
        alt="Entrance50"
      />
      <motion.div
        initial={{
          opacity: 0,
        }}
        animate={fade}
        className="d-content"
      >
        <p className="head">
          {tipe === "KAV" ? "Kavling Siap Dibangun" : tipe}
        </p>
        <h1>{name}</h1>
        <div className="d-address">
          <p>
            <span className="icon">
              <IoLocationSharp size={20} />
            </span>{" "}
            Jalan Gito Gati, Sleman
          </p>
        </div>
        <div className="d-detail">
          <h2>Detail Properti</h2>
          <p>
            {tipe?.[0]?.toUpperCase()}
            {tipe?.substring(1).toLowerCase()} dengan luas tanah {field} m
            <sup>2</sup>, dilengkapi fasilitas {bedroom} kamar tidur, {bathroom}{" "}
            kamar mandi, ruang tamu, ruang makan, dapur, carport yang dapat
            memuat {size} mobil.
          </p>
          <p>
            Memliki rooftop yang luas dan struktur bangunan dengan {tipe?.toLowerCase()} yang
            siap dikembangkan.
          </p>
        </div>
        <div className="d-description">
          {tipe.includes("TIPE") && (
            <p>
              <span className="icon">
                <BsHouseDoor size={20} />
              </span>
              {description}
            </p>
          )}
          {tipe.includes("TIPE") && (
            <p>
              <span className="icon">
                <IoBedOutline size={20} />
              </span>
              {bedroom} KT
            </p>
          )}
          {tipe.includes("TIPE") && (
            <p>
              <span className="icon">
                <BiBath size={20} />
              </span>
              {bathroom} KM
            </p>
          )}
          {tipe.includes("TIPE") && (
            <p>
              <span className="icon">
                <TbParking size={20} />
              </span>
              {size} mobil
            </p>
          )}
          <p>
            <span className="icon">
              <BiArea size={20} />
            </span>
            {field} m<sup>2</sup>
          </p>
        </div>
        <Link
          to={
            id ? "/griyaaltapesona/booking/" + id : "/griyaaltapesona/booking"
          }
        >
          <button className="btn-book">Book now</button>
        </Link>
      </motion.div>
    </div>
  );
};

export default General;
