import "./Modal.css"
import { FiCheck, FiX } from "react-icons/fi";
import { Link } from "react-router-dom";

const Modal = ({ open, onClose, message, error }) => {
    if (!open) return null

    return (
        <>
            {error
                ?
                <div onClick={onClose} className="overlay">
                    <div className="modal-success failed">
                        <div className="modal-center">
                            <span>{<FiX size={75} />}</span>
                            <h4>Error!</h4>
                            <p>{error}</p>
                            <Link to="/"><button onClick={onClose}>OK</button></Link>
                        </div>
                    </div>
                </div>
                :
                <div onClick={onClose} className="overlay">
                    <div className="modal-success success">
                        <div className="modal-center">
                            <span>{<FiCheck size={75} />}</span>
                            <h4>Success!</h4>
                            <p>{message}</p>
                            <Link to="/"><button onClick={onClose}>OK</button></Link>
                        </div>
                    </div>
                </div>}
        </>

    );
};

export default Modal;