import "./NotFound.css";
import {Link} from "react-router-dom";
import {Img404} from "../../assets"

const NotFound = () => {
    return(
        <div className="e-notfound">
            <div className="center">
                <img src={Img404} alt="not-found"/>
                <h1>Halaman tidak tersedia!</h1>
                <p>Maaf, halaman yang anda tuju tidak ditemukan.</p>
                <Link to="/"><button>Kembali ke home</button></Link>
            </div>
        </div>
    );
};

export default NotFound;