import React, { useState, useEffect } from "react";
import Area from "./Area/Area";
import Floor from "./Floor/Floor";
import General from "./General/General";
// import Interior from "./Interior/Interior";
import Map from "./Map/Map";
import Promo from "./Promo/Promo";
import Api from "../../../controller/produk";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import Loading from "../../../components/Loading/Loading";
import Maintenance from "../../Maintenance/Maintenance";
import gapDetail from "../../../constant/gapDetail.json";

const Detail = () => {
    console.log("gapDetail", gapDetail)
  const [home, setHome] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { id } = useParams(1);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (id) {
      Api.home(id)
        .then((res) => {
          setHome(res?.data);
        })
        .catch((error) => {
          setError(error.message);
          console.log(error);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setLoading(false);
      setHome(gapDetail);
    }
    // else {
    // Api.type50()
    //     .then(res => {
    //         setHome(res.data);
    //     })
    //     .catch(error => {
    //         setError(error.message);
    //         console.log(error);
    //     })
    //     .finally(() => {
    //         setLoading(false);
    //     });
    // }

    // eslint-disable-next-line
  }, [home?.status]);

  return (
    <>
      <Helmet>
        <title>Detail Rumah Griya Alta Pesona</title>
        <meta
          name="description"
          content="Rumah Tipe 100 dengan interior dan fasilitas lengkap, siap dikembangkan. Penasaran? Temukan disini!"
        />
        <link rel="canonical" href="/detail" />
      </Helmet>
      {loading && <Loading />}
      {error && <Maintenance error={error} />}
      {home && (
        <div>
          <General
            tipe={home?.data?.type?.name}
            name={home?.data?.name}
            id={home?.data?.id}
            description={home?.data?.type?.usage}
            size={home?.data?.type?.garage}
            bedroom={home?.data?.type?.bedroom}
            bathroom={home?.data?.type?.bathroom}
            field={home?.data?.field}
            image={home?.data?.type?.image}
          />
          {/* <Interior interiors={home?.data?.type?.interiors} /> */}
          <Area
            image={home?.data?.region?.image}
            description={home?.data?.region?.description}
          />
          <Floor floor={home?.data?.type?.floorPlan} />
          <Map />
          <Promo id={home?.data?.id} />
        </div>
      )}
    </>
  );
};

export default Detail;
