import { ImgMt } from "../../assets";
import { Link } from "react-router-dom";
import "../NotFound/NotFound.css";

const Maintenance = (props) => {
    return (
        <div className="e-notfound">
            <div className="center">
                <img src={ImgMt} alt="not-found" />
                <h1>Halaman sedang diperbaiki</h1>
                <p>{props.error
                    ? props.error
                    : "Maaf atas ketidaknyamanannya, kami sedang melakukan pengembangan. Kami akan kembali secepatnya."}</p>
                <Link to="/"><button>Kembali ke home</button></Link>
            </div>
        </div>
    );
};

export default Maintenance;