import React, { useEffect } from "react";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import "./Lokasi.css";
import { Maps } from "../../../../assets";

const Lokasi = () => {
  const { ref, inView } = useInView({
    threshold: 0.1,
  });
  const animation = useAnimation();

  useEffect(() => {
    if (inView) {
      animation.start({
        y: 0,
        opacity: 1,
        transition: {
          duration: 1,
          type: "spring",
          bounce: 0,
        },
      });
    }
  }, [inView, animation]);
  return (
    <div className="h-location">
      <img src={Maps} alt="maps" className="maps" />
      <div className="pad-3 location-content" ref={ref}>
        <motion.div
          initial={{
            y: "50vh",
            opacity: 0,
          }}
          animate={animation}
          className="container"
        >
          <h2>
            Temukan Lokasi{" "}
            <span
              style={{
                fontSize: 0,
                opacity: 0,
              }}
            >
              Grita Alta Pesona
            </span>
          </h2>
          <p className="gradient-text">Griya Alta Pesona</p>
          <h3>
            Jl. Gito Gati, Grojogan, Pandowoharjo, Kec. Sleman, Kabupaten
            Sleman, Daerah Istimewa Yogyakarta 55512
          </h3>
        </motion.div>
        <div>
          <a href="https://goo.gl/maps/KBQ5xvnEsgTPLdSm9" target="blank">
            <button className="button-2">Klik Disini</button>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Lokasi;
