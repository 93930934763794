import { Link } from "react-router-dom"
import { Home1, Home2, Home3, Home4 } from "../../../assets"
import "./GriyaParesia.css"

const GriyaParesia = () => {
    return (
        <div className="pad-1 griya-paresia" style={{
            paddingTop: 0,
        }}>
            <div className="griya-paresia__head">
                <h2 className="gradient-text" style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                }}>Griya Alta Pesona</h2>
                <img
                    src={Home1}
                    alt="Griya Paresia 1"
                    className="griya-paresia__img"
                />
            </div>
            <div className="griya-paresia__content">
                <div className="griya-paresia__sub-content">
                    <img
                        src={Home2}
                        alt="Griya Paresia 2"
                        className="griya-paresia__img_2"
                    />
                    <img
                        src={Home3}
                        alt="Griya Paresia 2"
                        className="griya-paresia__img_2"
                    />
                    <img
                        src={Home4}
                        alt="Griya Paresia 2"
                        className="griya-paresia__img_2"
                    />
                    <img
                        src={Home1}
                        alt="Griya Paresia 2"
                        className="griya-paresia__img_2"
                    />
                </div>
                <div className="griya-paresia__sub-content-1">
                    <p>Mempersembahkan hunian berkonsep green dengan berbagai macam aspek kenyamanan.</p>
                    <Link to="/produk/griyaaltapesona">
                        <button className="button-1-2">Lihat Griya Alta Pesona</button>
                    </Link>
                </div>
            </div>
        </div>
    )
}

export default GriyaParesia