import "./Faq.css";
import Api from "../../controller/faq";
import { useEffect, useState } from "react";
import Maintenance from "../Maintenance/Maintenance";
import Loading from "../../components/Loading/Loading";
import FaqCard from "./FaqCard/FaqCard";
import { Helmet } from "react-helmet-async";

const Faq = () => {
    const [faq, setFaq] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        Api.getFaq()
            .then(res => {
                setFaq(res.data?.data);
            })
            .catch(error => {
                setError(error.message);
                console.log(error);
            }).finally(() => {
                setLoading(false);
            });
        // eslint-disable-next-line
    }, [faq?.code]);

    const handleQuestion = index => {
        setFaq(
            faq.map((faq, i) => {
                if (i === index) {
                    faq.open = !faq.open;
                }

                return faq;
            })
        )
    }

    return (
        <>
            <Helmet>
                <title>Pertanyaan Tiga Dara Properti</title>
                <meta
                    name="description"
                    content="Bingung tentang Tiga Dara Properti? Cari kontak Tiga Dara Properti? Berbagai pertanyaan umum seputar perumahan Griya Alta Pesona dapat dilihat disini." />
                <link rel="canonical" href="/detail" />
            </Helmet>
            {loading && <Loading />}
            {error && <Maintenance error={error} />}
            {faq &&
                <div className="faq">
                    <h1>Pertanyaan yang Sering Diajukan</h1>
                    {faq?.length > 0 ?
                        <div className="question-box">
                            {faq && faq.map((item, index) => (
                                <FaqCard key={index} faq={item} index={index} handleQuestion={handleQuestion} />
                            ))}
                        </div> :
                        <h2>Belum ada pertanyaan</h2>
                    }

                </div>
            }
        </>

    );
};

export default Faq;