import "./Spesifikasi.css";
import {
  IoLocationOutline,
  IoScan,
  IoLeafOutline,
  IoKeyOutline,
  IoHomeOutline,
} from "react-icons/io5";
import React, { useEffect } from "react";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";

const Spesifikasi = (props) => {
  const { ref, inView } = useInView({
    threshold: 0.1,
  });
  const animation = useAnimation();

  useEffect(() => {
    if (inView) {
      animation.start({
        y: 0,
        opacity: 1,
        transition: {
          duration: 1,
          type: "spring",
          bounce: 0,
        },
      });
    }
  }, [inView, animation]);

  return (
    <div>
      <div ref={props.data} className="h-spesifikasi">
        <div
          className="bg"
          style={{
            background: `radial-gradient(50% 50% at 50.00% 50.05%, rgba(255, 255, 255, 0.00) 0%, var(--base) 90%), url(${props.img}), lightgray -708.777px 12px / 185.695% 98.633% no-repeat`,
            // background: `url(${props.img}), lightgray -708.777px 12px / 185.695% 98.633% no-repeat`,
          }}
        ></div>
        <div className="content" ref={ref}>
          <div className="head">
            <h2>
              Keunggulan{" "}
              <span
                style={{
                  fontSize: 0,
                  opacity: 0,
                }}
              >
                Griya Alta Pesona
              </span>
            </h2>
            <p>
              GRIYA <br />
              ALTA PESONA
            </p>
          </div>
          <div className="body">
            <motion.div
              initial={{
                y: "50px",
                opacity: 0,
              }}
              animate={animation}
              className="group max-width-1 "
            >
              <div className="group-title">
                <span>
                  <IoLocationOutline size={30} />
                </span>
                <h3>Lokasi Strategis</h3>
              </div>
              <p>
                Kawasan Griya Alta Pesona terdapat pada{" "}
                <b>lokasi yang sangat strategis.</b>
              </p>
            </motion.div>

            <motion.div
              initial={{
                y: "50px",
                opacity: 0,
              }}
              animate={animation}
              className="group"
            >
              <div className="group-title">
                <span>
                  <IoLeafOutline size={30} />
                </span>
                <h3>Kawasan Sejuk & Nyaman</h3>
              </div>
              <p>
                Kawasan hijau penuh dengan <b> pohon rindang yang sejuk.</b>
              </p>
            </motion.div>

            <motion.div
              initial={{
                y: "50px",
                opacity: 0,
              }}
              animate={animation}
              className="group"
            >
              <div className="group-title">
                <span>
                  <IoHomeOutline size={30} />
                </span>
                <h3>Model Bangunan Mudah Dikembangkan</h3>
              </div>
              <p>
                Bangunan di Griya Alta Pesona dapat <b>mudah dikembangkan</b>{" "}
                sesuai kemauan anda
              </p>
            </motion.div>

            <motion.div
              initial={{
                y: "50px",
                opacity: 0,
              }}
              animate={animation}
              className="group max-width-1 "
            >
              <div className="group-title">
                <span>
                  <IoKeyOutline size={30} />
                </span>
                <h3>Keamanan</h3>
              </div>
              <p>
                Kawasan perumahan dilengkapi dengan{" "}
                <b>keamanan yang sangat terjaga</b>
              </p>
            </motion.div>

            <motion.div
              initial={{
                y: "50px",
                opacity: 0,
              }}
              animate={animation}
              className="group"
            >
              <div className="group-title">
                <span>
                  <IoScan size={30} />
                </span>
                <h3>Konsep Smart Home</h3>
              </div>
              <p
                style={{
                  maxWidth: "500px",
                }}
              >
                Konsep desain ruangan yang Lega dan didukung sistem{" "}
                <b>Smart Home</b>
              </p>
            </motion.div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Spesifikasi;
