import "./Denah.css";
import React, { useEffect } from "react";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { IoCheckbox } from "react-icons/io5";

const Denah = (props) => {
  const { ref, inView } = useInView({
    threshold: 0.2,
  });
  const animation = useAnimation();

  useEffect(() => {
    if (inView) {
      animation.start({
        opacity: 1,
        scale: 1,
        transition: {
          duration: 1,
          type: "spring",
          bounce: 0.3,
        },
      });
    }
  }, [inView, animation]);

  return (
    <div className="h-denah" ref={ref}>
      <aside>
        <motion.img
          initial={{
            opacity: 0,
            scale: 0.5,
          }}
          animate={animation}
          src={"https://backend.tigadaraproperty.com/static/siteplan.png"}
          alt="Denah1"
          className="d-left"
        />
        <motion.div
          initial={{
            opacity: 0,
            scale: 0.5,
          }}
          animate={animation}
          className="d-right"
        >
          <h3>Peta Kawasan Griya Alta Pesona</h3>
          <div className="box">
            <div className="content-container">
              <div className="content">
                <IoCheckbox size={25} className="icon" />
                <div>
                  <h4>SPBU Pertamina</h4>
                  <p>(1 menit)</p>
                </div>
              </div>
              <div className="content">
                <IoCheckbox size={25} className="icon" />
                <div>
                  <h4>Masjid Suciati</h4>
                  <p>(3 menit)</p>
                </div>
              </div>
              <div className="content">
                <IoCheckbox size={25} className="icon" />
                <div>
                  <h4>Sleman City Hall</h4>
                  <p>(5 menit)</p>
                </div>
              </div>
              <div className="content">
                <IoCheckbox size={25} className="icon" />
                <div>
                  <h4>RSUD Sleman</h4>
                  <p>(12 menit)</p>
                </div>
              </div>
              <div className="content">
                <IoCheckbox size={25} className="icon" />
                <div>
                  <h4>Pusat Kota</h4>
                  <p>(17 menit)</p>
                </div>
              </div>
            </div>
          </div>
        </motion.div>
      </aside>
    </div>
  );
};

export default Denah;
