import { Detail50 } from "../../../assets";
import "./Form.css";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import { useState } from "react";
import { Modal } from "../../../components";
import { useNavigate } from "react-router-dom";
import { WA, phoneRegExp } from "../../../constant/config";
import Api from "../../../controller/produk";

const Form = (props) => {
  // console.log(props.home)
  const history = useNavigate();
  const [openModal, setOpenModal] = useState(false);
  const [error, setError] = useState(null);
  const id = props?.home?.id || null;

  const handleModal = () => {
    setOpenModal(false);
    history("/");
  };
  const doregister = (values) => {
    // homeId: id,
    //   firstName: "",
    //   lastName: "",
    //   phoneNumber: "",
    //   email: "",
    //   idCardNumber: "",
    //   address: "",
    //   contactAgreement: false,
    //   offerAgreement: false,
    //   termAgreement: false,
    Api.book(values)
      .then(() => {
        setOpenModal(true);
        const templateMessage = `
        Detail Booking ${props?.region?.name} ${props?.home?.name ?? "Griya Alta Pesona TIPE 100"}: 
         - Nama: ${values.firstName} ${values.lastName}
         - No HP: ${values.phoneNumber}
         - Email: ${values.email}
         - No Identitas: ${values.idCardNumber}
         - Alamat: ${values.address}`;

        const encodedMessage = encodeURIComponent(templateMessage);

        const whatsappUrl = `https://api.whatsapp.com/send?phone=${WA}&text=${encodedMessage}`;
        window.open(whatsappUrl, "_blank");
      })
      .catch((error) => {
        console.log(error);
        setError(error.message);
      })
      .finally(() => {
        formik.setSubmitting(false);
        formik.resetForm();
      });
  };

  const formik = useFormik({
    // initial values
    initialValues: {
      homeId: id,
      firstName: "",
      lastName: "",
      phoneNumber: "",
      email: "",
      idCardNumber: "",
      address: "",
      contactAgreement: false,
      offerAgreement: false,
      termAgreement: false,
    },
    // validation schema
    validationSchema: Yup.object({
      firstName: Yup.string().required("Tidak boleh kosong"),
      lastName: Yup.string().required("Tidak boleh kosong"),
      phoneNumber: Yup.string()
        .required("Tidak boleh kosong")
        .matches(phoneRegExp, "Nomer HP tidak valid"),
      email: Yup.string()
        .required("Tidak boleh kosong")
        .email("Format email tidak valid"),
      idCardNumber: Yup.string()
        .required("Tidak boleh kosong")
        .min(16, "No Identitas tidak valid")
        .max(16, "No Identitas tidak valid")
        .matches("^[0-9]*$", "No Identitas tidak valid"),
      address: Yup.string().required("Tidak boleh kosong"),
      contactAgreement: Yup.bool().isTrue("Field harus di isi"),
      offerAgreement: Yup.bool(),
      termAgreement: Yup.bool().isTrue("Field harus di isi"),
    }),
    // handle submission
    onSubmit: doregister,
  });
  return (
    <div className="b-content">
      <Modal
        open={openModal}
        onClose={handleModal}
        message="Permintaan anda terkirim"
      />
      <div className="b-detail">
        <img src={props?.home?.region?.image ?? Detail50} alt="tipe100" />
        <div className="b-detail-text">
          <h2>
            {props?.home?.name
              ? props?.home?.name
              : "Griya Alta Pesona TIPE 100"}
          </h2>
          <p>{`${props?.home?.region?.map?.location ?? "Jl Gito Gati"}`}</p>
        </div>
      </div>
      <div className="b-form">
        <label className="label-required">WAJIB DI ISI</label>
        <form onSubmit={formik.handleSubmit}>
          <div className="b-text">
            <div className="form-group">
              <label className="label-required">Nama Depan</label>
              <input
                type="text"
                name="firstName"
                placeholder="silakan isi nama depan anda"
                {...formik.getFieldProps("firstName")}
              />
              {formik.touched.firstName && formik.errors.firstName && (
                <div className="error">{formik.errors.firstName}</div>
              )}
            </div>
            <div className="form-group">
              <label className="label-required">Nama Belakang</label>
              <input
                type="text"
                name="lastName"
                placeholder="silakan isi nama belakang anda"
                {...formik.getFieldProps("lastName")}
              />
              {formik.touched.lastName && formik.errors.lastName && (
                <div className="error">{formik.errors.lastName}</div>
              )}
            </div>
            <div className="form-group">
              <label className="label-required">No HP</label>
              <input
                type="text"
                name="phoneNumber"
                placeholder="silakan isi no hp anda"
                {...formik.getFieldProps("phoneNumber")}
              />
              {formik.touched.phoneNumber && formik.errors.phoneNumber && (
                <div className="error">{formik.errors.phoneNumber}</div>
              )}
            </div>
            <div className="form-group">
              <label className="label-required">Email</label>
              <input
                type="text"
                name="email"
                placeholder="silakan isi email anda"
                {...formik.getFieldProps("email")}
              />
              {formik.touched.email && formik.errors.email && (
                <div className="error">{formik.errors.email}</div>
              )}
            </div>
            <div className="form-group">
              <label className="label-required">Nomer Identitas</label>
              <input
                type="text"
                name="idCardNumber"
                placeholder="silakan isi no identitas anda"
                {...formik.getFieldProps("idCardNumber")}
              />
              {formik.touched.idCardNumber && formik.errors.idCardNumber && (
                <div className="error">{formik.errors.idCardNumber}</div>
              )}
            </div>
            <div className="form-group">
              <label className="label-required">Alamat</label>
              <input
                type="text"
                name="address"
                placeholder="silakan isi alamat anda"
                {...formik.getFieldProps("address")}
              />
              {formik.touched.address && formik.errors.address && (
                <div className="error">{formik.errors.address}</div>
              )}
            </div>
          </div>
          <div className="b-foot">
            <div className="form-group">
              <input
                id="contactAgreement"
                type="checkbox"
                name="contactAgreement"
                checked={formik.values.contactAgreement}
                {...formik.getFieldProps("contactAgreement")}
              />
              <label htmlFor="contactAgreement" className="label-required">
                Saya mengerti dan setuju bahwa detail kontak saya akan disimpan
                dan digunakan oleh perusahaan untuk menanggapi permintaan kontak
                saya melalui Whatsapp jika saya mengirimkan nomor telepon saya.
                (Wajib)
              </label>
            </div>
            {formik.touched.contactAgreement &&
              formik.errors.contactAgreement && (
                <div className="error error-foot">
                  {formik.errors.contactAgreement}
                </div>
              )}
            <div className="form-group">
              <input
                id="offerAgreement"
                type="checkbox"
                name="offerAgreement"
                checked={formik.values.offerAgreement}
                {...formik.getFieldProps("offerAgreement")}
              />
              <label htmlFor="offerAgreement">
                Saya mengerti dan setuju bahwa perusahaan dapat mengirimi saya
                penawaran pemasaran dan pembaruan melalui Whatsapp. (Opsional)
              </label>
            </div>
            <div className="form-group">
              <input
                id="termAgreement"
                type="checkbox"
                name="termAgreement"
                checked={formik.values.termAgreement}
                {...formik.getFieldProps("termAgreement")}
              />
              <label htmlFor="termAgreement" className="label-required">
                Saya mengerti dan setuju bahwa saya telah membaca Ketentuan
                Penggunaan dan Kebijakan Privasi Perusahaan. (Wajib)
              </label>
            </div>
            {formik.touched.termAgreement && formik.errors.termAgreement && (
              <div className="error error-foot">
                {formik.errors.termAgreement}
              </div>
            )}
          </div>
          <div className="b-button">
            <Link to="/">
              <button className="btn-cancel" disabled={formik.isSubmitting}>
                Cancel
              </button>
            </Link>
            <button
              type="submit"
              className="btn-submit"
              disabled={formik.isSubmitting}
            >
              Kirim Permintaan
            </button>
          </div>
        </form>
      </div>
      {error && <p style={{ color: "red" }}>{error}</p>}
    </div>
  );
};

export default Form;
