import "./Testimoni.css";
import { Link } from "react-router-dom";
import { TCard } from "../../../../components";


const Testimoni = ({ testimoni, error }) => {
    return (
        <div className="t-jumbotron">
            <div>
                <section>
                    <p><span>&mdash;&mdash; </span>TESTIMONY</p>
                </section>
                <section style={{ marginBottom: "60px", alignItems: "baseline" }}>
                    <h2>Testimoni dari client</h2>
                    <Link to="/testimoni">See all testimonies <span>&#10230;</span></Link>
                </section>
            </div>
            {testimoni?.length > 0 ?
                <div
                    className="t-box">
                    {testimoni.map((item, index) => (
                        <div key={index} className="t-card">
                            <TCard testimoni={item} width={275} />
                        </div>
                    ))}
                </div> :
                <h3>Belum ada testimoni</h3>}
            {

                error && <p>{error}</p>
            }
            <div className="t-md">
                <Link to="/testimoni">See all testimonies <span>&#10230;</span></Link>
            </div>
        </div>
    );
};

export default Testimoni;