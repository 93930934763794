import "./Footer.css";
import { Logo } from "../../assets";
import { Link } from "react-router-dom";
import {
  WA,
  EMAIL,
  WEB,
  INSTAGRAM,
  YOUTUBE,
  TIKTOK,
} from "../../constant/config";

const Footer = () => {
  return (
    <div>
      <footer>
        <aside>
          <div className="f-left">
            <Link to="/">
              <img src={Logo} alt="Logo" />
            </Link>
            <p>
              We are PT Grha Prabala Sejahtera - Tiga Dara Properti that will
              help to find the best place for you.
            </p>
          </div>
          <div className="f-right">
            <div className="f-box-left">
              <div className="f-box ">
                <h2>Company</h2>
                <ul>
                  <li>
                    <Link to="/">About Us</Link>
                  </li>
                  <li style={{ color: "white" }}>Contact</li>
                  <li style={{ color: "white" }}>whitespace</li>
                </ul>
              </div>
              <div className="f-box">
                <h2>Contact Us</h2>
                <ul>
                  <li>
                    <a href={"https://" + WEB}>Website</a>
                  </li>
                  <li>
                    <a href={"mailto: " + EMAIL} target="blank">
                      Email
                    </a>
                  </li>
                  <li>
                    <a href={"https://wa.me/" + WA} target="blank">
                      Whatsapp
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="f-box-right">
              <div className="f-box">
                <h2>Support</h2>
                <ul>
                  <li>
                    <Link to="/faq">FAQ</Link>
                  </li>
                  <li>
                    <Link to="/griyaaltapesona/booking">Booking</Link>
                  </li>
                  <li>
                    <Link to="/testimoni">Testimoni</Link>
                  </li>
                </ul>
              </div>
              <div className="f-box">
                <h2>Social Media</h2>
                <ul>
                  <li>
                    <a href={INSTAGRAM} target="blank">
                      Instagram
                    </a>
                  </li>
                  <li>
                    <a href={YOUTUBE} target="blank">
                      Youtube
                    </a>
                  </li>
                  <li>
                    <a href={TIKTOK} target="blank">
                      Tiktok
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </aside>
        <hr />
        <div className="bside">
          <p>Copyright &#169; Tiga Dara Property. All rights reserved.</p>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
