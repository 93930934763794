import { Link } from "react-router-dom";
import "./Video.css";

const Video = () => {
  return (
    <div className="h-video">
      <div className="pad-1 content">
        <div>
          <p className="tagline">
            <span>&mdash;&mdash; </span>ABOUT US
          </p>
          <h2 className="head-1">
            Video Teaser{" "}
            <span
              style={{
                fontSize: 0,
                opacity: 0,
              }}
            >
              Griya Alta Pesona
            </span>
          </h2>

          <aside className="p-content">
            <p>
              Video preview Griya Alta Pesona hunian berkonsep <b>green</b>{" "}
              dengan berbagai aspek kenyamanan
            </p>
            <div style={{ paddingTop: 25 }}>
              <Link to="produk/griyaaltapesona">
                <button className="button-2">Informasi</button>
              </Link>
            </div>
          </aside>
        </div>

        <div>
          <iframe
            title="teaser"
            className="video"
            src="https://www.youtube.com/embed/Xe_n1DTx-Qg"
          ></iframe>
        </div>
      </div>
    </div>
  );
};

export default Video;
