import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Form from "./Form/Form";
import Head from "./Head/Head";
import { Helmet } from "react-helmet-async";
import Api from "../../controller/produk";
import Loading from "../../components/Loading/Loading";
import Maintenance from "../Maintenance/Maintenance";

const Booking = () => {
    const { id } = useParams("");
    const [home, setHome] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        if (id) {
            Api.home(id)
                .then(res => {
                    setHome(res.data?.data);
                    console.log(home);
                })
                .catch(error => {
                    setError(error.message);
                    console.log(error);
                })
                .finally(() => {
                    setLoading(false);
                });
        } else {
            setLoading(false);
        }
        // eslint-disable-next-line
    }, [home?.id])


    return (
        <>
            <Helmet>
                <title>Booking</title>
                <meta
                    name="description"
                    content="Booking form pemesanan - Pesan sekarang! Perumahan dengan lokasi stategis dekat kota Jogja dengan harga terjangkau" />
                <link rel="canonical" href="/booking" />
            </Helmet>
            {loading && <Loading />}
            {error && <Maintenance error={error} />}
            {!loading && !error &&
                <>
                    <Head />
                    <Form home={home} />
                </>}

        </>
    );
};

export default Booking;