import LCard from "../../components/LCard/LCard";
import React, { useEffect, useRef, useState } from "react";
import { motion } from "framer-motion";
import Api from "../../controller/produk";
import Loading from "../../components/Loading/Loading";
import Maintenance from "../Maintenance/Maintenance";
import { Helmet } from "react-helmet-async";
import "./Hero.css";
import ProdukNav from "./ProdukNav/ProdukNav";
import ProdukCard from "./ProdukCard/ProdukCard";

const Produk = () => {

    const query = new URLSearchParams(window.location.search);
    const [product, setProduct] = useState(null);
    const [produks, setProduks] = useState(null);
    const [type, setType] = useState('property');
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const ref = useRef(0);

    const handlerClick = e => {
        ref.current++;
        setType(e);
    }

    useEffect(() => {
        if (query.get("kawasan")) {
            setType(query.get("kawasan"));
        }
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        if (type === "property") {
            Api.property()
                .then(res => {
                    setProduks(res?.data?.data);
                })
                .catch((error) => {
                    setError(error.message);
                    console.log(error);
                })
                .finally(() => {
                    setLoading(false);
                });
        } else {
            Api.paresia()
                .then(res => {
                    setProduct(res?.data);
                })
                .catch((error) => {
                    setError(error.message);
                    console.log(error);
                })
                .finally(() => {
                    setLoading(false);
                });
        }

        // eslint-disable-next-line
    }, [product?.status, type]);

    return (
        <>
            <Helmet>
                <title>Produk Tiga Dara Properti</title>
                <meta
                    name="description"
                    content="Berbagai lahan/kavling kosong hingga rumah tipe 100 dengan harga terjangkau, lokasi stategis, dan mudah dikembangkan. Tertarik? Yuk booking sekarang!" />
                <link rel="canonical" href="/produk" />
            </Helmet>
            {loading && <Loading />}
            {error && <Maintenance error={error} />}
            {(produks || product) && <div className="p-hero">
                <ProdukNav handlerClick={handlerClick} type={type} />
                <motion.div
                    key={ref.current}
                    initial={{
                        scale: 0,
                        opacity: 0
                    }}
                    animate={{
                        scale: 1,
                        opacity: 1,
                        transition: {
                            duration: 1,
                            bounce: 0.2
                        }
                    }}
                >
                    {type === 'property'
                        ? <div className="p-property">{produks.map((item, index) => (<ProdukCard key={index} data={item} />))}</div>
                        :
                        <div className="p-content">
                            {product && product?.data.map((item, index) => (
                                <LCard item={item} key={index} />
                            ))}
                        </div>}

                </motion.div>
            </div>}
        </>

    );
};

export default Produk;