import "./Prasarana.css";
import React, { useEffect } from "react";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";

const Prasarana = ({ img }) => {
  const { ref, inView } = useInView({
    threshold: 0.2,
  });
  const animation = useAnimation();

  useEffect(() => {
    if (inView) {
      animation.start({
        opacity: 1,
        scale: 1,
        transition: {
          duration: 1,
          type: "spring",
          bounce: 0.3,
        },
      });
    }
  }, [inView, animation]);

  return (
    <div className="h-prasarana">
      <p>
        <span>&mdash;&mdash; </span>KAWASAN
      </p>
      <h2>
        Prasarana & Keamanan{" "}
        <span
          style={{
            fontSize: 0,
            opacity: 0,
          }}
        >
          Griya Alta Pesona
        </span>
      </h2>
      <aside ref={ref}>
        <motion.img
          initial={{
            opacity: 0,
            scale: 0.5,
          }}
          animate={animation}
          src={img}
          alt="Denah1"
          className="d-left"
        />
        <motion.div
          initial={{
            opacity: 0,
            scale: 0.5,
          }}
          animate={animation}
          className="d-right"
        >
          <div className="content">
            <ul>
              <li>
                Kawasan Griya Alta Pesona memberikan kemudahan akses dan hiburan
                berupa jalan selebar 9.00 m dan area playground.
              </li>
              <li>
                Kawasan ini juga didukung dengan saluran air yang terintegrasi
                juga kabel bawah tanah sehingga resiko terpengaruh cuaca lebih
                kecil dan tidak mengganggu visual kawasan.
              </li>
            </ul>
          </div>
        </motion.div>
      </aside>
    </div>
  );
};

export default Prasarana;
