import { Link } from "react-router-dom";
import "./ProdukCard.css";

const ProdukCard = ({ data }) => {
    const link = data?.id === 1 ? "griyaaltapesona": "giga" ;
    return (
        <>
            <Link to={"/produk/" + link} style={{textDecoration: "none"}}>
                <div
                    className="l-card-p"
                    style={{ background: `url(${data?.image}) rgba(112, 112, 112, 0.5)` }}
                >
                    <h2>{data?.name}</h2>
                </div>
            </Link>
        </>
    );
};

export default ProdukCard;