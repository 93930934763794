import "./Head.css";

const Head = (props) => {
    return(
        <div className="h-head">
            <img src={props.img} alt="entrance50"></img>
            <div className="h-point">
                <p onClick={props.executeScroll} className="box">Lokasi Strategis</p>
                <div className="vl box"></div>
                <p onClick={props.executeScroll} className="box">Kawasan Sejuk &#38; Nyaman</p>
                <div className="vl box"></div>
                <p onClick={props.executeScroll} className="box">Model Bangunan Mudah Dikembangkan</p>
                <div className="vl box"></div>
                <p onClick={props.executeScroll} className="box">Konsep Desain yang Lega</p>
                <div className="vl box"></div>
                <p onClick={props.executeScroll} className="box no-grid">Keamanan</p>
            </div>
        </div>
    );
};

export default Head;