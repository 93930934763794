import "./ProdukNav.css";
import { Detail50 } from "../../../assets";
import { IoHome, IoChevronDown } from "react-icons/io5";
import { useEffect, useState } from "react";

const ProdukNav = ({ handlerClick, type }) => {
  const [active, setActive] = useState(false);

  const handlerOpen = () => {
    setActive(!active);
  };

  const data = [
    {
      title: "Tipe Property",
      type: "property",
    },
    {
      title: "Griya Alta Pesona",
      type: "griyaaltapesona",
    },
  ];

  useEffect(() => {
    setActive(false);
  }, [type]);
  return (
    <>
      <div
        className="p-head"
        style={{
          backgroundImage: `url(${Detail50})`,
        }}
      >
        <h1>Produk</h1>
      </div>
      <div className="p-nav">
        <button className="p-button" onClick={handlerOpen}>
          <span>
            <IoHome size={20} />
          </span>
          <div className="p-head-content">
            <div className="p-text">
              {data.map((item) => (item.type === type ? item.title : null))}
            </div>
            <span>
              <IoChevronDown size={20} />
            </span>
          </div>
        </button>
        <div className={active ? "p-option p-active" : "p-option"}>
          {data.map((item, index) => (
            <div
              className="p-item"
              key={index}
              onClick={() => handlerClick(item.type)}
            >
              {item.title}
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default ProdukNav;
