import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import "./Header.css";
import { Banner } from "../../assets";
import { FaWhatsapp, FaBars, FaTimes } from "react-icons/fa";
import { IoMailOpenOutline } from "react-icons/io5";
import { MdKeyboardArrowUp } from "react-icons/md";
import { WA, EMAIL } from "../../constant/config";

class Header extends Component {
    state = {
        clicked: false,
        hoverwa: false,
        hoveremail: false,
    };


    handleClick = () => {
        this.setState({ clicked: !this.state.clicked });
    }

    handleFloat = () => {
        window.scroll({ top: 0, left: 0, behavior: 'smooth' });
    }

    handleHoverWa = () => {
        this.setState({ hoverwa: !this.state.hoverwa });
    }

    handleHoverEmail = () => {
        this.setState({ hoveremail: !this.state.hoveremail });
    }

    render() {
        return (
            <div>
                <nav>
                    <div className="l-click">
                        <NavLink activeclassname="active" to="/"><img src={Banner} alt="Logo" style={{paddingTop: 5}} height="40px" /></NavLink>
                    </div>
                    <button onClick={this.handleClick} className="menu-icon r-click">
                        {this.state.clicked
                            ? <FaTimes size={20} />
                            : <FaBars size={20} />
                        }
                    </button>
                    <div className={this.state.clicked ? 'nav-menu active' : 'nav-menu'}>
                        <ul>
                            <li><NavLink activeclassname="active" to="/" onClick={this.handleClick}>HOME</NavLink></li>
                            <li><NavLink activeclassname="active" to="/produk" onClick={this.handleClick}>PRODUK</NavLink></li>
                            <li><NavLink activeclassname="active" to="/testimoni" onClick={this.handleClick}>TESTIMONI</NavLink></li>
                            <li><NavLink activeclassname="active" to="/faq" onClick={this.handleClick}>FAQ</NavLink></li>
                        </ul>

                        <a href={"https://wa.me/" + WA} target="blank">
                            <button className="n-contact">
                                <div>
                                    <span style={{ marginRight: 5 }}><FaWhatsapp size={20} /></span>
                                    Whatsapp
                                </div>
                            </button>
                        </a>
                    </div>

                </nav>

                <div className="nav-float">
                    <div className={this.state.hoverwa || this.state.hoveremail ? "float-desc" : "float-desc deactive"}>
                        <div className={this.state.hoverwa ? "float-box active" : "float-box"}>
                            <p>Whatsapp: +{WA}</p>
                        </div>

                        <div className={this.state.hoveremail ? "float-box active" : "float-box"}>
                            <p>Email : {EMAIL}</p>
                        </div>
                    </div>


                    <div>
                        <div className="float-icon">
                            <a href={"https://wa.me/" + WA} target="blank">
                                <button className="btn-icon" onMouseEnter={this.handleHoverWa} onMouseLeave={this.handleHoverWa}>
                                    <FaWhatsapp size={35}/>
                                </button>
                            </a>


                            <a href={"mailto: " + EMAIL}>
                                <button className="btn-icon" onMouseEnter={this.handleHoverEmail} onMouseLeave={this.handleHoverEmail}>
                                    <IoMailOpenOutline size={35} />
                                </button>
                            </a>

                            <button className="btn-up" onClick={this.handleFloat}>
                                <MdKeyboardArrowUp />
                            </button>

                        </div>
                    </div>

                </div>
            </div>
        )
    };
};

export default Header;