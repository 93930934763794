import { Link } from "react-router-dom";
import { IoLocationSharp, IoBedOutline } from "react-icons/io5";
import { BiArea } from "react-icons/bi";
import { TbParking } from "react-icons/tb";
// import { rupiahFormatter } from "../../utils/rupiahFormatter";
import "./LCard.css";

const LCard = (props) => {
  return (
    <div className="l-card">
      <img src={props?.item?.type?.image} alt="img" />
      <div className="l-content">
        <div className="l-head">
          <h2>{props?.item?.name}</h2>
          <p>
            <span className="icon">
              <IoLocationSharp size={20} />
            </span>{" "}
            {props?.item?.region?.map?.location}
          </p>
        </div>
        <hr />
        <div className="l-detail">
          {props?.item?.type?.name.includes("TIPE") && (
            <p>
              <span className="icon">
                <IoBedOutline size={20} />
              </span>
              {props?.item?.type?.bedroom} KT
            </p>
          )}
          {props?.item?.type?.name.includes("TIPE") && (
            <p>
              <span className="icon">
                <TbParking size={20} />
              </span>
              {props?.item?.type?.garage} mobil
            </p>
          )}
          <p>
            <span className="icon">
              <BiArea size={20} />
            </span>
            {props?.item?.field} m<sup>2</sup>
          </p>
        </div>
        <div className="l-foot">
          <p>Silakan Hubungi Marketing</p>
          <Link to={"/griyaaltapesona/detail/" + props?.item?.id}>
            <button>Details</button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default LCard;
