import "./TCard.css";
import {Customer} from "../../assets/index";

const TCard = ({testimoni, width}) =>{
    return(
        <div className="c-customer">
            <img src={Customer} alt="Customer"/>
            <div>
                <h3 style={{width: width}}>{testimoni?.name ? testimoni?.name : "Anonim"}</h3>
                <p style={{width: width}}>{testimoni?.words}</p>
            </div>
        </div>
    );
};

export default TCard;