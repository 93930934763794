import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import Testimoni from "../Paresia/Homepage/Testimoni/Testimoni";
import Description from "./Description/Description";
import Header from "./Header/Header";
import Promotion from "./Promotion/Promotion";
import Reason from "./Reason/Reason";
import Video from "./Video/Video";
import TApi from "../../controller/testimoni";
import GriyaParesia from "./GriyaParesia/GriyaParesia";

const Home = () => {
  const [testimoni, setTestimoni] = useState(null);
  const [terror, setTerror] = useState(null);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    TApi.get()
      .then((res) => {
        setTestimoni(res?.data);
      })
      .catch((error) => {
        setTerror(error.message);
      });
    // eslint-disable-next-line
  }, [testimoni?.status]);

  return (
    <>
      <Helmet>
        <title>Graha Prabala Sejahtera - Tiga Dara Property</title>
        <meta
          name="description"
          content="Graha Prabala Sejahtera - Tiga Dara Property sebuah kawasan hunian rumah yang memiliki konsep green yang akan memberikan pengalaman tinggal serasa di pegunungan. Tinggal di tempat strategis."
        />
        <link rel="canonical" href="/" />
      </Helmet>
      <Header />
      <div className="home">
        <Description />
        <GriyaParesia />
        <Video />
        <Reason />
        <Promotion />
        <Testimoni testimoni={testimoni?.data} error={terror} />
      </div>
    </>
  );
};

export default Home;
