import "./Head.css";

const Head = () =>{
    return(
        <div className="b-head">
            <h1>Booking Form</h1>
        </div>
    );
};

export default Head;