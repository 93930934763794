import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import {Header, Footer} from "./components"
import Booking from "./pages/Booking";
import Faq from "./pages/Faq";
import Home from "./pages/Home";
import Maintenance from "./pages/Maintenance/Maintenance";
import NotFound from "./pages/NotFound/NotFound";
import Detail from "./pages/Paresia/Detail";
import Homepage from "./pages/Paresia/Homepage";
import Produk from "./pages/Produk";
import Testimoni from "./pages/Testimoni";

function App() {
  return (
    <Router>
      <div className="App">
        <Header />
        <Routes>
          <Route exact path="/" element={<Home />}/>
          <Route path="produk" element={<Produk />}/>
          <Route path="produk/griyaaltapesona" element={<Homepage />}/>
          <Route path="griyaaltapesona/detail" element={<Detail />}/>
          <Route path="griyaaltapesona/detail/:id" element={<Detail />}/>
          <Route path="griyaaltapesona/booking" element={<Booking />}/>
          <Route path="griyaaltapesona/booking/:id" element={<Booking />}/>
          <Route path="faq" element={<Faq />}/>
          <Route path="maintenance" element={<Maintenance/>}/>
          <Route path="testimoni" element={<Testimoni />}/>
          <Route path="*" element={<NotFound />}/>
        </Routes>
        <Footer />
      </div>
    </Router>
    
  );
}

export default App;
