import Api from "../api/config";

// eslint-disable-next-line import/no-anonymous-default-export
export default{
    property: () => Api.get('/region'),
    paresia: () => Api.get('/home'),
    type: (type) => Api.get(`/home?type=${type}`),
    home: (id) => Api.get(`/home/${id}`),
    type50: () => Api.get("/null/home"),
    book: (data)=> Api.post("/book", data),
};