import { Template2 } from "../../../assets";
import { WA } from "../../../constant/config";
import "./Promotion.css";

const Promotion = () => {
  return (
    <div className="h-promotion">
      <div className="pad-1 content">
        <div className="left">
          <p className="tagline">
            <span>&mdash;&mdash; </span>ABOUT US
          </p>
          <h2 className="head-1">
            Simulasi Harga dan Promo{" "}
            <span
              style={{
                fontSize: 0,
                opacity: 0,
              }}
            >
              Griya Alta Pesona
            </span>
          </h2>

          <aside
            className="p-content"
            style={{
              marginTop: "10px",
            }}
          >
            <p>Dapatkan penawaran harga terbaik dari kami.</p>
            <p>
              Harga rumah kami terus mengalami penyesuaian harga setiap
              tahunnya, hal ini karena semakin banyaknya orang yang ingin
              tinggal di daerah yang sangat strategis.
            </p>
            <p>
              Dapatkan harga perdana dari kami di cluster terbaru ini, agar kamu
              dapat mengoptimalkan potensi keuntungan nantinya!
            </p>
            <p>
              Silakan <b>kontak marketing</b> kami dan dapatkan rincian
              harganya.
            </p>
          </aside>

          <div style={{ paddingTop: 25 }}>
            <a href={"https://wa.me/" + WA} target="blank">
              <button className="button-2">Whatsapp</button>
            </a>
          </div>
        </div>
        <img className="image" src={Template2} alt="promo dan harga" />
      </div>
    </div>
  );
};

export default Promotion;
