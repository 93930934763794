import "./FaqCard.css";
import { MdKeyboardArrowUp } from "react-icons/md"

const FaqCard = ({faq, index, handleQuestion}) => {

    return (
        
            <div 
                key={index}
                onClick={() => handleQuestion(index)}
            >
                <div className="question-group">
                    <p>{faq?.question}</p>
                    <span className={faq?.open ? "rotate" : "back"}><MdKeyboardArrowUp size={25} /></span>
                </div>
                <div className={faq?.open ? "active" : "answer"}>{faq?.answer}</div>
            </div>

    );
};

export default FaqCard;