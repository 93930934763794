import { Logo } from "../../assets";
import "./Loading.css";

const Loading = () =>{
    return(
        <div className="loading">
            <div className="content">
                <img src={Logo} alt="logo"/>
                <div className="loading-dot">
                    <div className="bounce1"></div>
                    <div className="bounce2"></div>
                    <div className="bounce3"></div>
                </div>
            </div>
            
        </div>
    );
}

export default Loading;