import "./Map.css";
import React, { useEffect } from "react";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";

const Map = () => {
    const { ref, inView } = useInView({
        threshold: 0.2
    });

    const fade = useAnimation();

    useEffect(() => {
        if (inView) {

            fade.start({
                opacity: 1,
                transition: {
                    duration: 1,
                }
            })
        }

    }, [inView, fade]);
    return (
        <div className="d-map" ref={ref}>
            <motion.div
                initial={{
                    opacity: 0
                }}
                animate={fade}
                className="content">
                <iframe title="map" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15814.879174850794!2d110.3756015!3d-7.7131859!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x651ad756801d612d!2sPerumahan%20Griya%20Paresia!5e0!3m2!1sen!2sid!4v1658740562416!5m2!1sen!2sid" style={{ border: 0 }} loading="lazy"></iframe>
                <div className="detail">
                    <h2>Maps</h2>
                    <p>Kawasan Griya Alta Pesona terletak di daerah yang strategis di Jalan Gito Gati, Sleman.</p>
                    <a href="https://goo.gl/maps/KBQ5xvnEsgTPLdSm9" target="blank"><button>Open Maps</button></a>
                </div>
            </motion.div>
        </div>
    );
};

export default Map;