import "./Floor.css";
import React, { useEffect } from "react";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { IoCheckbox } from "react-icons/io5";

const items = [
  "3 Kamar Tidur",
  "Ruang Tamu",
  "2 Kamar Mandi",
  "Taman Belakang",
  "Dapur",
  "Carpot 2 Mobil",
  "Ruang Keluarga",
  "Ruang Makan",
];

const Floor = ({ floor }) => {
  const { ref, inView } = useInView({
    threshold: 0.2,
  });

  const animation = useAnimation();

  useEffect(() => {
    if (inView) {
      animation.start({
        scale: 1,
        opacity: 1,
        transition: {
          duration: 1,
          type: "spring",
          bounce: 0,
        },
      });
    }
  }, [inView, animation]);
  return (
    <div className="d-floor" ref={ref}>
      <h2>
        Denah Lantai{" "}
        <span
          style={{
            fontSize: 0,
            opacity: 0,
          }}
        >
          Griya Alta Pesona
        </span>
      </h2>
      <div className="container">
        <motion.img
          initial={{
            scale: 0,
            opacity: 0,
          }}
          animate={animation}
          src={floor}
          alt="Denah50"
          className="left"
        />
        <motion.div
          initial={{
            scale: 0,
            opacity: 0,
          }}
          animate={animation}
          className="right"
        >
          {items.map((item, index) => (
            <div key={index} className="f-item">
              <IoCheckbox size={27} className="icon" />
              <p>{item}</p>
            </div>
          ))}
        </motion.div>
      </div>
    </div>
  );
};

export default Floor;
